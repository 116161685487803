.btn {
  cursor: pointer;
  padding: 10px 30px;
  border-radius: 25px; //
  border: none;
  font: {
    size: 1.1rem;
    weight: 600;
  }
  transition-property: background, color;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}
@mixin darkColors($bg_color) {
  background: $bg_color;
  color: white;
}
@mixin lightColors($bg_color) {
  background: $bg_color;
  color: black;
}
.btn.darkgray {
  @include darkColors(var(--darkgray));
}
.btn.gray {
  @include lightColors(var(--gray));
}
.btn.outlined.darkgray {
  background: none;
  color: var(--darkgray);
  border: 1px solid var(--darkgray);

  &:hover {
    @include darkColors(var(--darkgray));
  }
}
.btn:disabled {
  background: var(--gray) !important;
  color: rgb(176, 176, 176) !important;
  border: 1px solid transparent !important;
}
