.root {
  .head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
  }
}

.json {
  $color--value: #8cdcfe;
  $color--number: #b5ce89;
  $color--string: #e19a65;
  $color--curlyBraces: #d7cc03;
  $color--null_undefined: #358cd6;
  $bg: #1e1e1e;

  padding: 20px;
  border-radius: 5px;
  background-color: $bg;

  .curlyBraces {
    color: $color--curlyBraces;
  }
  .curlyBraces.open {
    margin-left: 5px;
  }
  .body {
    $objectBody--ml: 15px;

    display: flex;
    flex-direction: column;
    row-gap: 2px;
    margin: 5px 0;
    margin-left: $objectBody--ml;
    .comma {
      color: white;
      margin-left: 1px;
    }
    .k {
      color: $color--value;
    }
    .v {
      margin-left: 5px;
    }
    .string {
      color: $color--string;
    }
    .number {
      color: $color--number;
    }
    .null_undefined {
      color: $color--null_undefined;
    }
    .object {
      margin-left: $objectBody--ml;
    }
  }
}
