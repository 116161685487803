.root {
  width: 140px;
  .label {
    font-size: 1.1rem;
    margin-bottom: 10px;
  }

  .select {
    position: relative;
    $border-R: 14px;
    $border: 1px solid #dddddd;

    .viewValue,
    .status {
      width: 100%;
      padding: 10px 15px;
      font-size: 1rem;
      background-color: white;
      text-align: start;
    }
    .viewValue.active {
      border-top: $border;
      border-left: $border;
      border-right: $border;
      border-bottom: 1px solid transparent;
      border-radius: $border-R $border-R 0 0;
    }
    .viewValue {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: $border;
      border-radius: $border-R;

      .arrow {
        width: 12px;
        height: 8px;

        transition: transform 0.2s ease;
        transform: rotate(180deg);
      }
      .arrow.active {
        transform: rotate(0);
      }
    }
    .pending {
      color: var(--lightblue-10);
    }
    .issue {
      color: var(--crimson);
    }
    .resolved {
      color: var(--green-10);
    }

    .body {
      position: absolute;
      width: 100%;
      .status {
        cursor: pointer;
        border-left: $border;
        border-right: $border;
        border-top: none;
        border-bottom: none;

        &:last-child {
          border-left: $border;
          border-right: $border;
          border-bottom: $border;
          border-radius: 0 0 $border-R $border-R;
        }

        &:hover {
          background-color: #e1e1e1;

          &:last-child {
            border-radius: 0 0 $border-R $border-R;
          }
        }
      }
    }
  }
}
