.loaderWrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
}
.root {
  $bg: white;

  .nav {
    display: flex;
    align-items: flex-end;

    &Item {
      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      height: 45px;
      padding: 10px 0;
      border-radius: 15px 15px 0 0;
      border: none;
      background: var(--gray);
    }
    .navItem.active {
      background: $bg;
    }
  }
  .body {
    background: $bg;
    border-radius: 0 20px 0 0;
    padding: 50px;
  }
}
