.root {
  display: flex;
  flex-direction: column;

  .textarea {
    resize: none;
    border-width: 1px;
    border-style: solid;
    border-color: var(--gray);
    border-radius: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    padding: 10px;
  }
  .textareaAuto {
    overflow-y: hidden;
  }
}
