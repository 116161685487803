@import "@/shared/assets/styles/templates/variables.scss";

.root {
  .grid {
    display: grid;
    grid-template-columns: 0.5fr 0.7fr 1fr 0.3fr;
    column-gap: 20px;
  }

  .wrapper {
    position: relative;
    height: 100vh - $header--height;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .table {
      width: 100%;

      .loaderWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 100%;
      }

      .th {
        cursor: auto;
        padding: 7px 0;
        font: {
          size: 1rem;
        }
      }

      .tbody {
        position: relative;
        $border-radius: 20px;
        border-radius: $border-radius;
        margin-top: 15px;

        .filter {
          position: absolute;
          right: -150px;

          display: flex;
          flex-direction: column;
          row-gap: 8px;

          .resetStatusBtn {
            background-color: lightgray;
          }

          &Btn {
            cursor: pointer;
            border: none;
            padding: 8px 20px;
            border-radius: 10px;
            color: black;

            font: {
              size: 0.8rem;
              weight: 500;
            }
          }
          .filterBtn.pending {
            background-color: var(--lightblue-5);
          }
          .filterBtn.issue {
            background-color: var(--pink-5);
          }
          .filterBtn.resolved {
            background-color: var(--green-5);
          }
        }

        .tr {
          &:first-child {
            border-radius: $border-radius $border-radius 0 0;
          }

          &:last-child {
            border-radius: 0 0 $border-radius $border-radius;
          }

          &.pending {
            background-color: var(--lightblue);
            &:nth-child(2n) {
              background-color: var(--lightblue-5);
            }
          }
          &.issue {
            background-color: var(--pink-5);
            &:nth-child(2n) {
              background-color: var(--pink);
            }
          }
          &.resolved {
            background-color: var(--green);
            &:nth-child(2n) {
              background-color: var(--green-5);
            }
          }
          .td {
            padding: 16px 0;
            color: black;
            font: {
              size: 0.95rem;
              weight: 500;
            }

            &:first-child,
            &:last-child {
              text-align: center;
            }
          }
        }
      }
    }

    .pagination {
      display: flex;
      align-items: center;
      padding: 20px 0;

      .paginationItem {
        &:not(:last-child) {
          margin-right: 10px;
        }
      }

      .paginationItemLink {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 30px;
        height: 30px;
        border: none;
        background: none;

        &Text {
          font-size: 0.9rem;
        }
      }
      .paginationItemActive {
        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 100%;
        background: var(--darkgray);

        color: white;
      }
    }
  }
}
