@import url("fonts.css");
@import "lib/reset";
@import "colors";
@import "templates/variables";
@import "templates/text";

* {
  font-family: "Inter", sans-serif;
}

body {
  background-color: var(--gray-5);
}

.container {
  width: $container--width;
  margin: 0 auto;
}

.truncation {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
