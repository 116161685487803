@import "@/shared/assets/styles/templates/variables.scss";

.root {
  height: $header--height;
  .wrapper {
    display: flex;
    align-items: center;
    column-gap: 20px;
    padding-top: 25px;
    width: max-content;
    margin: 0 auto;

    .btn {
      display: flex;
      align-items: center;
      column-gap: 10px;

      &:hover {
        rect {
          fill: white;
        }
      }

      .btnSVG {
        width: 20px;
        height: 20px;
        rect {
          transition: fill 0.3s ease;
        }
      }
      .btnSVG.active {
        rect {
          fill: white;
        }
      }
    }
  }
}
