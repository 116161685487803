.root {
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;

    .form {
      display: flex;
      flex-direction: column;
      padding: 40px 50px;
      border-radius: 20px;
      background: white;

      .title {
        margin-bottom: 25px;
      }

      .field {
        display: flex;
        flex-direction: column;
        row-gap: 8px;

        &:not(:last-child) {
          margin-bottom: 15px;
        }

        .input {
          padding: 10px;
          border-radius: 5px;
          width: 270px;
        }
        .input.error {
          border: 1px solid red;
        }
      }

      .errorMessage {
        color: red;
        font-size: 0.8rem;
      }
      .submitBtn {
        margin-top: 30px;
      }
    }
  }
}
