:root {
  --lightblue: #b0dfe4;
  --lightblue-5: #a0d9e0;
  --lightblue-10: #09b5c9;
  --crimson: #df266a;
  --pink: #d8adbc;
  --pink-5: #d49db1;
  --green: #97d099;
  --green-5: #8cc48e;
  --green-10: #50aa53;
  --gray: #d9d9d9;
  --gray-5: #eeeeee;
  --darkgray: #444444;
}
