.title {
  margin-top: 40px;
}
.sendComment {
  display: flex;
  align-items: center;
  margin-top: 20px;
  column-gap: 30px;
  .textarea {
    width: 100%;
  }
  .textarea.error {
    border: 1px solid red;
  }
  .errorMessage {
    font-size: 0.9rem;
    color: red;
  }

  &SVG {
    cursor: pointer;
    width: 55px;
    height: 55px;
  }
  &Btn {
    background: none;
    border: none;
  }
}
.comments {
  margin-top: 25px;

  display: flex;
  flex-direction: column;
  row-gap: 25px;

  .comment {
    width: max-content;
    max-width: 100%;
    padding: 20px;
    background: var(--gray);
    border-radius: 20px;

    &Login {
      font-size: 1.2rem;
      font-weight: 600;
    }
    &Description {
      margin-top: 15px;
      letter-spacing: 0.01vw;
      font-weight: 500;
    }
    &CreatedAt {
      font-size: 0.9rem;
      margin-top: 15px;
      color: #737373;
    }
  }
}
