.root {
  position: relative;

  .head {
    display: flex;
    justify-content: space-between;

    .email {
      margin-bottom: 15px;
    }
    .link {
      font: {
        size: 1.1rem;
        weight: 500;
      }
    }
  }

  .description {
    margin-top: 40px;

    &Text {
      line-height: 140%;
    }
  }

  .photo {
    cursor: pointer;
    margin-top: 30px;
    border: none;

    &Img {
      width: 100%;
    }
  }
  .photo.active {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 95%;
    margin: auto;
    z-index: 1;
    background: none;
  }
  .photoActiveLayout {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: -1;

    background: rgba(0, 0, 0, 0.5);
  }
}
